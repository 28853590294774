import { useState, useEffect } from "react";

const ContactForm = ({onStepChange, setName, setEmail, initialEmail}) => {

    // Set intial email  to what is stored in Redux store
    const [localEmail, setLocalEmail] = useState(initialEmail || '');

    const [isNameValid, setNameValid] = useState(true);
    const [isEmailValid, setEmailValid] = useState(true);

    useEffect(() => {
        if (initialEmail) {
          setLocalEmail(initialEmail);
          validateEmail(initialEmail);
        }
      }, [initialEmail]);

    // Email validation function
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setLocalEmail(newEmail);
        setEmailValid(validateEmail(newEmail));
        setEmail(newEmail); // Update the parent component's email state
    };

    const handleSubmit = async (event)=>{
        event.preventDefault();

        // Validation logic
        const nameValue = event.target.name.value;
        // const emailValue = event.target.email.value;
        const emailValue = localEmail;


        // // Email validation function
        // const validateEmail = (email) => {
        //     // Regular expression for email validation
        //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //     return emailPattern.test(email);
        // };

        if (nameValue.trim() === '') {
            setNameValid(false);
        }
        if (emailValue.trim() === '' || !validateEmail(emailValue)) {
            setEmailValid(false);
        }

        // Continue with form submission if all inputs are valid
        if (nameValue.trim() !== '' && emailValue.trim() !== '' && validateEmail(emailValue)) {
            onStepChange();
            setName(event.target.name.value)
            setEmail(event.target.email.value)
        }

    }

    return(
        <form onSubmit={handleSubmit} noValidate>
            <div className="relative my-3 rounded-md shadow-sm">
                <input
                    type="text"
                    name="name"
                    id="name"
                    // className="block w-full h-14 text-lg rounded-md border-0 py-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-400 placeholder:text-gray-500 placeholder:font-medium focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-mb sm:leading-6"
                    className={`block w-full h-14 text-lg rounded-md border-0 py-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-400 placeholder:text-gray-500 placeholder:font-medium focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-mb sm:leading-6
                    ${isNameValid ? '' : 'ring-red-500 bg-red-200'}`}
                    
                    placeholder="Full name"
                    aria-invalid="true"
                    aria-describedby="email-error"
                    onChange={() => setNameValid(true)}
                    // required
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                </div>
            </div>
            {!isNameValid && 
                <p className="mt-2 text-md font-medium text-red-600" id="name-error">Name is required.</p>}


            <div className="relative my-3 rounded-md shadow-sm">
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={localEmail}
                    // className="block w-full h-14 text-md rounded-md border-0 py-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-400 placeholder:text-gray-500 placeholder:font-medium focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-md sm:leading-6"
                    className={`block w-full h-14 text-lg rounded-md border-0 py-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-400 placeholder:text-gray-500 placeholder:font-medium focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-mb sm:leading-6
                    ${isEmailValid ? '' : 'ring-red-500 bg-red-200'}`}
                    placeholder="Email"
                    aria-invalid="true"
                    aria-describedby="email-error"
                    // onChange={() => setEmailValid(true)}
                    onChange={handleEmailChange}
                    // required
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                </div>
            </div>
            { !isEmailValid && 
                <p className="mt-2 text-md font-medium text-red-600" id="email-error">Email is required.</p> }

            <button
                id="submit"
                className="rounded-md min-w-full bg-red-500 px-3 my-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-red-600 outline outline-2 outline-red-600"
                >
                NEXT 
            </button>
        </form>
    )
}

export default ContactForm; 